import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ES Server - 1.7.4 International`}</h2>
    <p>{`The following is a guide on how to use Offline Provision Portal (OPP) to deploy ES Server 1.7.4 International software.`}</p>
    <br />
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p>{`Because we utilize BD Active Diretory via SSO to authenticate, `}<a parentName="p" {...{
          "href": "https://provision-portal.bddevops.com/"
        }}>{`Provision Portal`}</a>{` should `}<strong parentName="p">{`ONLY`}</strong>{` be used from a BD authorized Computer or Laptop and not on a shared device or VM.`}</p>
    </InlineNotification>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Minimum Requirements" mdxType="AccordionItem">
        <h4>{`Supported Operating System(s)`}</h4>
        <ul>
          <li parentName="ul">{`Windows Server 2019`}</li>
        </ul>
        <h4>{`Supported Software Versions`}</h4>
        <ul>
          <li parentName="ul">{`Microsoft SQL Server™ 2019`}</li>
          <li parentName="ul">{`Windows PowerShell™ 5.1`}</li>
        </ul>
        <h4>{`User Accounts`}</h4>
        <ul>
          <li parentName="ul">{`Admin Account (typically CFNAdmin)`}</li>
          <li parentName="ul">{`Service Account (typically CFNService)/gMSA (Group Managed Service Account)`}</li>
        </ul>
        <h4>{`Execution Prep Steps`}</h4>
        <ul>
          <li parentName="ul">{`Turn off `}<inlineCode parentName="li">{`IE Enhanced Security Configuration`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Open `}<inlineCode parentName="li">{`Server Manager`}</inlineCode>{` and click on `}<inlineCode parentName="li">{`Local Server`}</inlineCode></li>
              <li parentName="ul">{`Find `}<inlineCode parentName="li">{`IE Enhanced Security Configuration`}</inlineCode>{` on the right upper section and click `}<inlineCode parentName="li">{`On`}</inlineCode>{` next to it`}</li>
              <li parentName="ul">{`In the pop up window, set both Administrators and Users to `}<inlineCode parentName="li">{`Off`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Ok`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">{`Download Google Chrome or Microsoft Edge browser - this is required to use `}<a parentName="li" {...{
              "href": "https://provision.bddevops.com"
            }}>{`BD Provisioning`}</a>
            <ul parentName="li">
              <li parentName="ul">{`Ensure `}<inlineCode parentName="li">{`IE Enhanced Security Configuration`}</inlineCode>{` is off`}</li>
              <li parentName="ul">{`Launch Internet Explorer`}</li>
              <li parentName="ul">{`Navigate to `}<inlineCode parentName="li">{`https://www.google.com/chrome/`}</inlineCode>{` or `}<inlineCode parentName="li">{`https://www.microsoft.com/en-us/edge`}</inlineCode>{` and download/install`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Ensure the Time Settings are correct for the VMs local time zone`}
            <ul parentName="li">
              <li parentName="ul">{`If the time is not within 5 minutes +/- of the local time, the BDAgent will fail to install`}</li>
            </ul>
          </li>
        </ul>
        <br />
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Note:`}</strong>{` The SQL User Account must utilize Windows Authentication and must have `}<inlineCode parentName="p">{`SysAdmin`}</inlineCode>{` access in the SQL instance during database deployment runtime. The SQL User can be the same as the Admin Account or an aditional account provided by the Customer with the proper access to SQL.`}</p>
        </InlineNotification>
      </AccordionItem>
      <AccordionItem title="ES Server Deployment Definitions" mdxType="AccordionItem">
        <h4>{`Deployment Types:`}</h4>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Clean (Fresh) Install`}</strong>{`: New installations for new customers or for Test Environments`}</li>
          <li parentName="ol"><strong parentName="li">{`Server Migration or Migration Upgrade`}</strong>{`: Existing customers that GCS will be migrating to a new Server OS and/or SQL Server Version. Databases will be upgraded.`}</li>
        </ol>
        <h4>{`Server Types:`}</h4>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Application Server`}</strong>{`: ES Pyxis Applications, Reporting Attachements, IIS Web Server`}</li>
          <li parentName="ol"><strong parentName="li">{`Database Server`}</strong>{`: SQL Server to host ES Application Databases`}</li>
          <li parentName="ol"><strong parentName="li">{`Integrated Solutions Server (ISS)`}</strong>{` - SQL Server with SSRS, IIS Web Server, ESR, HSV, IDM, MMS API`}</li>
          <li parentName="ol"><strong parentName="li">{`All In One Server (AIO)`}</strong>{`: Contains all of the above`}</li>
          <li parentName="ol"><strong parentName="li">{`DataSync Server`}</strong>{`: DataSync 1.0 Service only (Only used for Migrations)`}</li>
        </ol>
      </AccordionItem>
    </Accordion>
    <h2>{`Deployment Glossary`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Components" mdxType="Tab">
        <h4>{`Component Types`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Server Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`All In One`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`All ES application deploy to this VM`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Application`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Application Server for ES Pyxis applications & Web Page`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integrated Solutions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Reporting Server with SQL SSRS, HSV, IDM`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Database`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Server Host`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync (DS)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync “1.0” Server`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Workflows" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.4 - AIO Clean Install`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean (fresh) AIO Server  environment.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.4 - 3 Box Clean Install`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean multi-server environment. This should be used to deploy App and ISS only, if a Customer manages their own Database Server.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.4 - AIO Migration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean (fresh) AIO Server and migrate the Databases for environment.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.4 - 3 Box Migration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP/DB/ISS/DS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean (fresh) 3 Box environment and migrate the Databases for upgrade. This Workflow can deploy Application and ISS Servers only, if a Customer manages their own Database Server. It can also deploy DataSync Servers as part of the full environment.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Test Station 1.7.4`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a test station on Windows 10 OS (Test Station VM)`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Workflow Steps" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Step`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Validate Credentials`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Verifies Service Account credentials are valid`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ServiceAccount`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Verifies Service Account format and the output is used in subsequent SQL install`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy AIO Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the AIO Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Database Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the Database (SQL) Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Application Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the Application Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Integrated Solutions Server Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the Integrated Solutions Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy DataSync Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a DataSync 1.0 standalone Server (Only used for Migrations)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Application Server Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy fresh DSServerOLTP, Scheduling Services, DSRF, CFUpdateService`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Integrated Solutions Server Databases Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy fresh DSServerReports, HSV, IDM Databases`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Stop BD Services`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attempts to stop and disable running BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Stop IS Server BD Services and ETL`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attempts to stop and disable ETL and running BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Backup Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`MANUAL STEP:`}</strong>{` Backup all production databses after all services are stopped`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Restore Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`MANUAL STEP:`}</strong>{` Restore all production databses after all services are stopped`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO Server Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade the AIO Server software`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Application Server Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade the Application Server software`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Integrated Solutions Server Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade the ISS Server software`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade DSServerOLTP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute the DSServerOLTP upgrade scripts`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade Application Server Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute the DSRF, Scheduling Services upgrade scripts`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start Web Applications`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start IIS, Start PES and application pools, configure IDS`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start DataSync Service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP/DataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start DataSync Services (1.0 and 2.0)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start Active Directory Sync Service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start Active Directory Sync Service`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start External Messaging Service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start External Messaging Service`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start and Configure Application Server Services`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start and Configure ISS Services`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start BD Services, IIS, configure IDM`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade ISS Server Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute the DSServerReports, HSV, IDM upgrade scripts`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Update DataSync Values`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Updates the Core DataSync Server information in DSServerOLTP when migrating to a new system`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Post Installation Configuration Tool for IDS and IDM`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute PICT Tool`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Configure TDE`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute SQL encryption on ES databases`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Execute Action Docs" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Step`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`N/A`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`N/A`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute Actions are NOT supported with Offline Provision Portal release.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Warning:`}</strong>{` Ensure the Customer has issued the correct Windows Server 2019 Virtual Machine, connected to a Domain with the proper user accounts created. If a Customer provides their own SQL Server, verify you are able to connect with the provided account credentials before proceeding.`}</p>
    </InlineNotification>
    <h2>{`Choose Your Deployment Path`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Clean Install" mdxType="Tab">
        <InlineNotification mdxType="InlineNotification">
          <p>{`Clean installations require a new Virtual Machine for each: Application Server, Integrated Solutions Server, and Database Server (if applicable) OR a new All in One (AIO) Server`}</p>
        </InlineNotification>
        <br />
        <Tabs mdxType="Tabs">
          <Tab label="All-In-One (AIO)" mdxType="Tab">
            <h4>{`Copy Files to the Device`}</h4>
            <ol>
              <li parentName="ol">{`Download the latest OPP runtime for Windows`}
                <ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`EFT - Released\\Dispensing\\Tools`}</inlineCode>{` `}<a parentName="li" {...{
                      "href": "https://eft.carefusion.com"
                    }}>{`EFT`}</a></li>
                  <li parentName="ul"><inlineCode parentName="li">{`Artifactory`}</inlineCode>{` `}<a parentName="li" {...{
                      "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
                    }}>{`here`}</a></li>
                </ul>
              </li>
              <li parentName="ol">{`Extract the Zip file to a location on your computer`}</li>
              <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the device you wish to deploy product software from the bundle.`}</li>
              <li parentName="ol">{`Download the offline bundle for the product you wish to deploy from `}<a parentName="li" {...{
                  "href": "https://eft.carefusion.com"
                }}>{`EFT`}</a>{` and copy it to the same location as the OPP runtime.`}
                <ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`EFT Path: Released\\Dispensing\\MedStation\\International\\MedStation ES\\Server\\1.7.x`}</inlineCode></li>
                </ul>
              </li>
            </ol>
            <br />
            <InlineNotification kind='info' mdxType="InlineNotification">
              <p>{`The Offline Provision Portal.exe application and the offline bundle MUST be in the same folder on the target device.`}</p>
            </InlineNotification>
            <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
            <ol>
              <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
              <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
              <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
              <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
              <li parentName="ol">{`Follow the instructions for Creating the Deployment Profile.`}</li>
            </ol>
          </Tab>
          <Tab label="Multi-Box" mdxType="Tab">
            <InlineNotification kind='info' mdxType="InlineNotification">
              <p>{`For a multi-box deployment, one copy of “Offline Provision Portal.exe” must be on each box. The box where you would like to
control the deployment from and interact with the UI (primary) must additionally have the bundle.`}</p>
            </InlineNotification>
            <h4>{`Copy Files to the Devices`}</h4>
            <ol>
              <li parentName="ol">{`Download the latest OPP runtime for Windows`}
                <ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`EFT - Released\\Dispensing\\Tools`}</inlineCode>{` `}<a parentName="li" {...{
                      "href": "https://eft.carefusion.com"
                    }}>{`EFT`}</a></li>
                  <li parentName="ul"><inlineCode parentName="li">{`Artifactory`}</inlineCode>{` `}<a parentName="li" {...{
                      "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
                    }}>{`here`}</a></li>
                </ul>
              </li>
              <li parentName="ol">{`Extract the Zip file to a location on your computer.`}</li>
              <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the devices you wish to deploy the product. You must repeat this step for each device in the deployment.`}</li>
              <li parentName="ol">{`Download the offline bundle for the product you wish to deploy from `}<a parentName="li" {...{
                  "href": "https://eft.carefusion.com"
                }}>{`EFT`}</a>{` and copy it to the same location as the OPP runtime. This is the device where you will be interacting with the UI.`}
                <ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`EFT Path: Released\\Dispensing\\MedStation\\International\\MedStation ES\\Server\\1.7.x`}</inlineCode></li>
                </ul>
              </li>
            </ol>
            <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
            <ol>
              <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
              <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
              <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
              <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
              <li parentName="ol">{`Follow the instructions for deploying the product found in the `}<a parentName="li" {...{
                  "href": "/guides/es-server-1.7.2"
                }}>{`User Guide`}</a>{` for the product you are deploying to create
a new deployment.`}</li>
              <li parentName="ol">{`Once the deployment is created, note down the deployment code shown in the UI.`}</li>
            </ol>
            <h4>{`Launch Offline Provision Portal on agent devices (e.g Database/ISS/DataSync server)`}</h4>
            <ol>
              <li parentName="ol">{`Launch the Offline Provision Portal.exe application on all agent devices.`}</li>
              <li parentName="ol">{`Enter the primary device’s IP address and deployment code in the UI and click Submit`}</li>
              <li parentName="ol">{`Return to the primary server to continue the deployment.`}</li>
              <li parentName="ol">{`Follow the instructions for Creating the Deployment Profile`}</li>
            </ol>
          </Tab>
        </Tabs>
        <h4>{`Creating the Deployment Profile`}</h4>
        <ol>
          <li parentName="ol">{`Under the `}<inlineCode parentName="li">{`CUSTOMERS`}</inlineCode>{` section on the left hand side, navigate to or search for the correct `}<strong parentName="li">{`Customer Account`}</strong></li>
          <li parentName="ol">{`Click on the Customer and select appropriate Facility Name to be taken to its specific Deployment management page`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Deployment Profiles`}</inlineCode></li>
          <li parentName="ol">{`Assuming this is a new deployment, click on `}<inlineCode parentName="li">{`Add Profile`}</inlineCode></li>
          <li parentName="ol">{`For the `}<inlineCode parentName="li">{`Profile Display Name`}</inlineCode>{`, name the profile according to the System Release and Server Deployment Type`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.4 - 3 Box Clean Install`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Product`}</inlineCode>{` and `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.4 - AIO Clean Install`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.4 - 3 Box Clean Install`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`+ CREATE`}</inlineCode></li>
          <li parentName="ol">{`Enter the Parameters to be used for the Deployment - `}<a parentName="li" {...{
              "href": "#deployment-reference"
            }}>{`Deployment Reference`}</a>
            <br />
Make sure you select appropriate parameter for Select SQL locale. This parameter supports en-GB for UK-English and zh-TW for Chinese languages.
          </li>
          <li parentName="ol">{`After the Parameters are set, the Profile will automatically be saved to be used for the Server Deployment`}</li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`There is no need to `}<em parentName="p">{`Save`}</em>{` as Provision Portal saves upon entry, also making editing parameters easier`}</p>
        </InlineNotification>
        <h4>{`Creating the Deployment`}</h4>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Server Deployments`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`New Deployment`}</inlineCode>{` to create a New Deployment`}</li>
          <li parentName="ol">{`Input a descriptive, relevant `}<inlineCode parentName="li">{`Deployment Name`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Next`}</inlineCode>
            <ol parentName="li">
              <li parentName="ol">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.4 - 3 Box Clean Install`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`ES Server 1.7.4`}</inlineCode>{` box`}</li>
          <li parentName="ol">{`Set the Quantity for the applicable `}<a parentName="li" {...{
              "href": "#deployment-glossary"
            }}>{`Component(s)`}</a></li>
        </ol>
        <br />
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Note:`}</strong>{` `}<em parentName="p">{`There can only be a MAXIMUM 1 APP, 1 DB, 1 ISS for multibox deployment.`}</em></p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 6
        }}>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create`}</inlineCode>{` and you should see a `}<inlineCode parentName="li">{`Successfully created deployment`}</inlineCode>{` banner at the top of the page`}</li>
        </ol>
        <h4>{`Executing the Deployment`}</h4>
        <ol>
          <li parentName="ol">{`When the Amazon SSM Agent registers the VM with AWS, the `}<inlineCode parentName="li">{`Status`}</inlineCode>{` will read `}<inlineCode parentName="li">{`Online`}</inlineCode></li>
          <li parentName="ol">{`If 3 Box, assign the `}<inlineCode parentName="li">{`Components`}</inlineCode>{` with the correct VM hostnames`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`SAVE COMPONENT ASSIGNMENTS`}</inlineCode></li>
          <li parentName="ol">{`When everyone is ready to start the execution, click on `}<inlineCode parentName="li">{`RUN WORKFLOW`}</inlineCode></li>
          <li parentName="ol">{`The `}<inlineCode parentName="li">{`Execute Workflow`}</inlineCode>{` window should pop up`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{` from the dropdown`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`, the one you created`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Run`}</inlineCode></li>
          <li parentName="ol">{`The first Step to `}<inlineCode parentName="li">{`Validate Credentials`}</inlineCode>{` will execute automatically`}
            <ol parentName="li">
              <li parentName="ol">{`If this step fails, Service User account credentials were not entered correctly in the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`. Please update the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` and then return to the Deployment. Click `}<inlineCode parentName="li">{`Run Workflow`}</inlineCode>{` to load the newly updated `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` for the Deployment.`}</li>
            </ol>
          </li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Each Deployment Step is separated out with PLAY button (`}<inlineCode parentName="p">{`Run Step`}</inlineCode>{` button).
`}<strong parentName="p">{`Each Step is meant to run in order, only when the previous step reports`}</strong>{` `}<inlineCode parentName="p">{`Success`}</inlineCode>{`.
`}<strong parentName="p">{`During deployment, server will reboot multiple times, please make sure to log back in in order to continue.`}</strong></p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 10
        }}>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the next step, `}<inlineCode parentName="li">{`Deploy Database Server`}</inlineCode>{`, in the list`}</li>
          <li parentName="ol">{`Once the Step reports `}<inlineCode parentName="li">{`Success`}</inlineCode>{`, click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` on the next step down in the list and follow the rest of the Deployment Workflow in order`}</li>
          <li parentName="ol">{`Once the last Step is complete, ES Server is deployed`}</li>
          <li parentName="ol">{`Continue on to `}<a parentName="li" {...{
              "href": "#post-installation-configuration"
            }}>{`Post Installation Configuration`}</a>{` Steps`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p>{`User has the ability to `}<inlineCode parentName="p">{`CANCEL`}</inlineCode>{` a Step Execution. This should be used `}<strong parentName="p">{`ONLY`}</strong>{` to stop a Step if an error was made. This action “hard stops” the execution and does not have the ability to safely exit (It is likely you can replay the Step when ready to proceed)`}</p>
        </InlineNotification>
        <br />
      </Tab>
      <Tab label="Server Migrations" mdxType="Tab">
        <InlineNotification mdxType="InlineNotification">
          <p>{`Server Migrations require a new Application Server, Integrated Solutions Server, and Database Server (if applicable) OR a new AIO Server. Server Migration is designed to allow the user to deploy the Server Software ahead of downtime and then execute the Database Migration and Database Upgrades during planned downtime`}</p>
        </InlineNotification>
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning:`}</strong>{` For more information please see Offline Provision Portal page.`}</p>
        </InlineNotification>
        <br />
        <Tabs mdxType="Tabs">
          <Tab label="All-In-One (AIO)" mdxType="Tab">
            <h4>{`Copy Files to the Device`}</h4>
            <ol>
              <li parentName="ol">{`Download the latest OPP runtime for Windows `}<a parentName="li" {...{
                  "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
                }}>{`here`}</a></li>
              <li parentName="ol">{`Extract the Zip file to a location on your computer`}</li>
              <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the device you wish to deploy product software from the bundle.`}</li>
              <li parentName="ol">{`Download the offline bundle for the product you wish to deploy from `}<a parentName="li" {...{
                  "href": "https://eft.carefusion.com"
                }}>{`EFT`}</a>{` and copy it to the same location as the OPP runtime.`}</li>
            </ol>
            <br />
            <InlineNotification kind='info' mdxType="InlineNotification">
              <p>{`The Offline Provision Portal.exe application and the offline bundle MUST be in the same folder on the target device.`}</p>
            </InlineNotification>
            <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
            <ol>
              <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
              <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
              <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
              <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
              <li parentName="ol">{`Follow the instructions for Creating the Deployment Profile.`}</li>
            </ol>
          </Tab>
          <Tab label="Multi-Box" mdxType="Tab">
            <InlineNotification kind='info' mdxType="InlineNotification">
              <p>{`For a multi-box deployment, one copy of “Offline Provision Portal.exe” must be on each box. The box where you would like to
control the deployment from and interact with the UI (primary) must additionally have the bundle.`}</p>
            </InlineNotification>
            <h4>{`Copy Files to the Devices`}</h4>
            <ol>
              <li parentName="ol">{`Download the latest OPP runtime for Windows `}<a parentName="li" {...{
                  "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
                }}>{`here`}</a></li>
              <li parentName="ol">{`Extract the Zip file to a location on your computer.`}</li>
              <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the devices you wish to deploy the product. You must repeat this step for each device in the deployment.`}</li>
              <li parentName="ol">{`Downlaod the offline bundle for the product you wish to deploy and copy it to the same location as the OPP runtime on the
device you wish to be the primary device. This is the device where you will be interacting with the UI.`}</li>
            </ol>
            <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
            <ol>
              <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
              <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
              <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
              <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
              <li parentName="ol">{`Follow the instructions for deploying the product found in the `}<a parentName="li" {...{
                  "href": "/guides/es-server-1.7.2"
                }}>{`User Guide`}</a>{` for the product you are deploying to create
a new deployment.`}</li>
              <li parentName="ol">{`Once the deployment is created, note down the deployment code shown in the UI.`}</li>
            </ol>
            <h4>{`Launch Offline Provision Portal on agent devices (e.g Database/ISS/DataSync server)`}</h4>
            <ol>
              <li parentName="ol">{`Launch the Offline Provision Portal.exe application on all agent devices.`}</li>
              <li parentName="ol">{`Enter the primary device’s IP address and deployment code in the UI and click Submit`}</li>
              <li parentName="ol">{`Return to the primary server to continue the deployment.`}</li>
              <li parentName="ol">{`Follow the instructions for Creating the Deployment Profile`}</li>
            </ol>
          </Tab>
        </Tabs>
        <h3>{`Creating the Deployment Profile`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Deployment Profiles`}</inlineCode></li>
          <li parentName="ol">{`Assuming this is a new deployment, click on `}<inlineCode parentName="li">{`Add Profile`}</inlineCode></li>
          <li parentName="ol">{`For the `}<inlineCode parentName="li">{`Profile Display Name`}</inlineCode>{`, name the profile according to the System Release and Server Deployment Type`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.4 - 3 Box Migration`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Product`}</inlineCode>{` and `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.4 - AIO Migration`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.4 - 3 Box Migration`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`+ CREATE`}</inlineCode></li>
          <li parentName="ol">{`Enter the Parameters to be used for the Deployment - `}<a parentName="li" {...{
              "href": "#deployment-reference"
            }}>{`Deployment Reference`}</a>
            <pre parentName="li"><code parentName="pre" {...{}}>{`- *Pro Tip: Copy and Paste as much information as you can from the Imp Form and from the Customer VM(s)*
`}</code></pre>
            <br />
Make sure you select appropriate parameter for Select SQL locale. This parameter supports en-GB for UK-English and zh-TW for Chinese languages.
          </li>
          <li parentName="ol">{`After the Parameters are set, the Profile will automatically be saved to be used for the Server Deployment`}</li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`There is no need to `}<em parentName="p">{`Save`}</em>{` as Provision Portal saves upon entry, also making editing parameters easier`}</p>
        </InlineNotification>
        <h3>{`Creating the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Server Deployments`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`New Deployment`}</inlineCode>{` to create a New Deployment`}</li>
          <li parentName="ol">{`Input a descriptive, relevant `}<inlineCode parentName="li">{`Deployment Name`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Next`}</inlineCode></li>
          <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`ES Server 1.7.4`}</inlineCode>{` box`}</li>
          <li parentName="ol">{`Set the Quantity for the applicable Component(s)`}</li>
        </ol>
        <br />
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Note:`}</strong>{` There can only be MAXIMUM 1 APP, 1 DB, 1 ISS for multibox deployment.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 6
        }}>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create`}</inlineCode>{` and you should see a `}<inlineCode parentName="li">{`Successfully created deployment`}</inlineCode>{` banner at the top of the page`}</li>
        </ol>
        <br />
        <h3>{`Executing the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`If 3 Box, assign the `}<inlineCode parentName="li">{`Component`}</inlineCode>{` with the correct VM hostname (Need to click on Deployments link in order to refresh the page)`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`SAVE COMPONENT ASSIGNMENTS`}</inlineCode></li>
          <li parentName="ol">{`When everyone is ready for deployment time, click on `}<inlineCode parentName="li">{`RUN WORKFLOW`}</inlineCode></li>
          <li parentName="ol">{`The `}<inlineCode parentName="li">{`Execute Workflow`}</inlineCode>{` window should pop up`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{` from the dropdown`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`, the one you created`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Run`}</inlineCode></li>
          <li parentName="ol">{`The first Step to `}<inlineCode parentName="li">{`Validate Credentials`}</inlineCode>{` will execute automatically`}
            <ol parentName="li">
              <li parentName="ol">{`If this step fails, Service User account credentials were not entered correctly in the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`. Please update the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` and then return to the Deployment. Click `}<inlineCode parentName="li">{`Run Workflow`}</inlineCode>{` to load the newly updated `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` for the Deployment.`}</li>
            </ol>
          </li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Each Deployment Step is separated out with PLAY button (`}<inlineCode parentName="p">{`Run Step`}</inlineCode>{` button).
`}<strong parentName="p">{`Each Step is meant to run in order, only when the previous step reports`}</strong>{` `}<inlineCode parentName="p">{`Success`}</inlineCode>{`.
`}<strong parentName="p">{`During deployment, server will reboot multiple times, please make sure to log back in in order to continue.`}</strong></p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 10
        }}>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the next Steps to Deploy the Server`}
            <ul parentName="li">
              <li parentName="ul">{`You can deploy multiple Servers in parallel at this point if you choose`}</li>
              <li parentName="ul">{`The Step `}<inlineCode parentName="li">{`Deploy DataSync Server`}</inlineCode>{` can be skipped if it’s not appicable  `}</li>
            </ul>
          </li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning`}</strong>{`: `}<strong parentName="p">{`THIS IS SITE DOWNTIME`}</strong>{` At this point, the Customer will need to be notified and cofirm they are ready for the ES Servers and MedStations to go offline.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 11
        }}>
          <li parentName="ol">{`Once the Customer gives the “OK” for downtime, proceed to the `}<inlineCode parentName="li">{`Update DataSync Values`}</inlineCode>{` Step. Verify the New Sync values are in the Source DSServerOLTP database. This will update the CORE Sync Server only.`}</li>
          <li parentName="ol">{`Continue on to Steps to Stop BD Services. Verify all BD Services and ETL are stopped and disabled before proceeding`}</li>
          <li parentName="ol">{`The next two Steps to `}<inlineCode parentName="li">{`Backup Databases`}</inlineCode>{` and `}<inlineCode parentName="li">{`Restore Databases`}</inlineCode>{` are manual. You `}<strong parentName="li">{`MUST`}</strong>{` backup databases, copy them to the new SQL server, and restore them`}</li>
          <li parentName="ol">{`Continue on to Upgrade the Databases step`}</li>
          <li parentName="ol">{`Click Run step next to Database cleanup tool - dummy step.
If upgrading from ES 1.6.1 or earlier, the Database Cleanup Tool must be executed after upgrading the DSServerOLTP database but before starting any services on the target server. The Provision Portal step labeled Database cleanup tool - dummy step describes the process of executing the script and the location of where it can be found.`}</li>
          <li parentName="ol">{`In the please confirm window select `}<inlineCode parentName="li">{`proceed`}</inlineCode></li>
          <li parentName="ol">{`Navigate to the file path \\BD\\DeploymentScripts\\es-server_data_cleanup_5_3_x.sql.`}</li>
          <li parentName="ol">{`Run the es-server_data_cleanup_5_3_x.sql file from SSMS window.`}</li>
          <li parentName="ol">{`After executing the referenced sql script, proceed with the remaining workflow and move on to the `}<a parentName="li" {...{
              "href": "#post-installation-configuration"
            }}>{`Post Installation Configuration`}</a>{` Steps`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p>{`User has the ability to `}<inlineCode parentName="p">{`CANCEL`}</inlineCode>{` a Step Execution. This should be used `}<strong parentName="p">{`ONLY`}</strong>{` to stop a Step if an error was made. This action “hard stops” the execution and does not have the ability to safely exit (It is likely you can replay the Step when ready to proceed)`}</p>
        </InlineNotification>
      </Tab>
      <Tab label="Test Station" mdxType="Tab">
        <InlineNotification mdxType="InlineNotification">
          <p>{`Test Station can be installed on Windows 10 64-bit 21H2 or greater version. VM requires 20 Gb for drive C. Also, UAC must be disabled and permissions set for administrator to perform software installations and subsequent updates. Configure the firewall with the required ports.`}</p>
        </InlineNotification>
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning:`}</strong>{` For more information please see Offline Provision Portal page.`}</p>
        </InlineNotification>
        <h4>{`Copy Files to the Devices`}</h4>
        <ol>
          <li parentName="ol">{`Download the latest OPP runtime for Windows `}<a parentName="li" {...{
              "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
            }}>{`here`}</a></li>
          <li parentName="ol">{`Extract the Zip file to a location on your computer.`}</li>
          <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the devices you wish to deploy the product. You must repeat this step for each device in the deployment.`}</li>
          <li parentName="ol">{`Downlaod the offline bundle for the product you wish to deploy and copy it to the same location as the OPP runtime on the
device you wish to be the primary device. This is the device where you will be interacting with the UI.`}</li>
        </ol>
        <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
        <ol>
          <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
          <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
          <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
          <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
        </ol>
        <h4>{`Creating the Deployment Profile`}</h4>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Deployment Profiles`}</inlineCode></li>
          <li parentName="ol">{`Assuming this is a new deployment, click on `}<inlineCode parentName="li">{`Add Profile`}</inlineCode></li>
          <li parentName="ol">{`For the `}<inlineCode parentName="li">{`Profile Display Name`}</inlineCode>{`, name the profile according to the System Release and Server Deployment Type`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`ES Test Station 1.7.4`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Product`}</inlineCode>{` and `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Test Station 1.7 Deployment`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`+ CREATE`}</inlineCode></li>
          <li parentName="ol">{`Enter the Parameters to be used for the Deployment - `}<a parentName="li" {...{
              "href": "#deployment-reference"
            }}>{`Deployment Reference`}</a></li>
          <li parentName="ol">{`Parameters:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`System Release Version: enter the system release build version`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`Station Type: select station type`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`Service User Account Name: If the VM is joined to a customer domain use the customer provided service user account formatted as Domain\\Username. If not joined to a customer domain, use NT AUTHORITY\\NETWORK SERVICE.`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`Service User Account Password: Enter the password for the user account name above. If using NT AUTHORITY\\NETWORK SERVICE, enter a SPACE.`}</inlineCode>
                <br />
Make sure you select appropriate parameter for Select SQL locale. This parameter supports en-GB for UK-English and zh-TW for Chinese languages.
              </li>
            </ul>
          </li>
          <li parentName="ol">{`After the Parameters are set, the Profile will automatically be saved to be used for the Deployment`}</li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`There is no need to `}<em parentName="p">{`Save`}</em>{` as Provision Portal saves upon entry, also making editing parameters easier`}</p>
        </InlineNotification>
        <h3>{`Creating the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Server Deployments`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`New Deployment`}</inlineCode>{` to create a New Deployment`}</li>
          <li parentName="ol">{`Input a descriptive, relevant `}<inlineCode parentName="li">{`Deployment Name`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Next`}</inlineCode></li>
          <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`ES Test Station 1.7`}</inlineCode>{` box`}</li>
          <li parentName="ol">{`Set the Quantity for the applicable Component(s)`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create`}</inlineCode></li>
        </ol>
        <h3>{`Executing the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`SAVE COMPONENT ASSIGNMENTS`}</inlineCode></li>
          <li parentName="ol">{`When everyone is ready for deployment time, click on `}<inlineCode parentName="li">{`RUN WORKFLOW`}</inlineCode></li>
          <li parentName="ol">{`The `}<inlineCode parentName="li">{`Execute Workflow`}</inlineCode>{` window should pop up`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{` from the dropdown`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`, the one you created`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Run`}</inlineCode></li>
          <li parentName="ol">{`Under the actions column, click the `}<inlineCode parentName="li">{`ExecuteAction`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Continue with deployment. (Device will restart during workflow deployment, please log back in to continue with deployment)`}</li>
        </ol>
      </Tab>
    </Tabs>
    <h2>{`Deployment Reference`}</h2>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Parameter Reference" mdxType="AccordionItem">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Expected Format`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Required?`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account Name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`domain\\username`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account/gMSA username to run ES Applications, Web AppPools, and is added to SQL Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account Password `}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`password`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account password provided by the Customer`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL/Admin User (Username Only)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`username`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL User Account name. This could be the Admin user account (CFNAdmin) or an additional account that has proper SQL access`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL/Admin Password`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`password`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL/Admin User Account password provided by the Customer`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Account Type`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`custom`}</inlineCode>{` or `}<inlineCode parentName="td">{`gMSA`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select account type `}<inlineCode parentName="td">{`custom`}</inlineCode>{` for regular service account.`}<inlineCode parentName="td">{`gMSA`}</inlineCode>{` for gMSA account type`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deployment Drive Letter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`C:`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select ES Deployment Drive Letter where all software will be installed. This parameter supports any drive letter`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Data Drive Letter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`C:`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select SQL Data Drive where all databases will be stored. This parameter supports any drive letter`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Backup Drive Letter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`C:`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select SQL Backup Drive where all backups will be stored. This parameter supports any drive letter`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Log Drive Letter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`C:`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Select SQL Log Drive where all logs will be stored. This parameter supports any drive letter`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Database Server Name, SQL Port`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`server-name`}</inlineCode>{` or `}<inlineCode parentName="td">{`server-name.domain.com`}</inlineCode>{` or `}<inlineCode parentName="td">{`server-name, 1111`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Database server name either as Hostname or FQDN. Port number can be specified with a `}<inlineCode parentName="td">{`,`}</inlineCode>{` then port number. Named Instance is specified as `}<inlineCode parentName="td">{`server-name\\instance-name`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`CCE Messages Path `}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<DriveLetter>:\\CCEMessages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Folder path to store CCE Messages`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Archive File Path`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<DriveLetter>:\\ES_Archiving`}</inlineCode>{` or `}<inlineCode parentName="td">{`\\\\servername\\path`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Folder/UNC path to store Archive files`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`EPIC Issuer URL Path`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`http://localhost/`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL path for EPIC Issuer`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS Inventory Frequency`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<Integer>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS inventory frequency in minutes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS Inventory KP Authentication Secret`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`KP authenticaion secret`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS Inventory Server ID (Customer ID)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`12345678`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Customer Site ID`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`HSV Super Admin Account Name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`su`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Super Admin account name for HSV`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`IDS URL`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`https://<AppServerFQDN>:11998`}</inlineCode>{` or `}<inlineCode parentName="td">{`https://<AIOFQDN>:11998`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL to IDS instance. Please use the Fully Qualified Domain Name(FQDN)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`FHIR Database Purge Frequency`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<Integer>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`FHIR database purge frequency in days`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Report Server Name - FQDN`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<ISSorAIOservername.domain.com>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is used to configure ES Applications the utilize Reporting Services. Please use the FQDN of the Server with SSRS deployed: ISS or AIO.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Website Name - PES for AIO. Default Web Site for ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Default Web Site`}</inlineCode>{` or `}<inlineCode parentName="td">{`PES`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is used for the IDM Web application deployment. If it is an AIO, set this to `}<inlineCode parentName="td">{`PES`}</inlineCode>{`. If this is an 3 box, set this to `}<inlineCode parentName="td">{`Default Web Site`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select HSIO if required`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`No`}</inlineCode>{` or `}<inlineCode parentName="td">{`Yes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(Optional) This is optional component. Select Yes if necessary`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select HSDM if required`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`No`}</inlineCode>{` or `}<inlineCode parentName="td">{`Yes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(Optional) This is optional component. Select Yes if necessary`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select FHIR if required`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`No`}</inlineCode>{` or `}<inlineCode parentName="td">{`Yes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(Optional) This is optional component. Select Yes if necessary`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select HSV Infusion if required`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`No`}</inlineCode>{` or `}<inlineCode parentName="td">{`Yes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(Optional) This is optional component. Select Yes if necessary`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Encryption Cert Password`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`password`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Certificate password for SQL TDE encryption`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select SQL locale`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`en-US`}</inlineCode>{` or `}<inlineCode parentName="td">{`en-GB`}</inlineCode>{` or `}<inlineCode parentName="td">{`zh-TW`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This will set SQL collation. Default is en-US (International deployments)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Customer Provided SQL Instance`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`No`}</inlineCode>{` or `}<inlineCode parentName="td">{`Yes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(Optional) This is optional component. Select Yes if necessary`}</td>
            </tr>
          </tbody>
        </table>
      </AccordionItem>
      <AccordionItem title="Troubleshooting Deployments" mdxType="AccordionItem">
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p>{`Ensure the Deployment Profile is created correctly `}<strong parentName="p">{`BEFORE`}</strong>{` proceeding with kicking off the deployment. Deployment Profiles are “stateful” meaning once you START a deployment with a Profile, those values are locked in place, even if you update/fix them.`}</p>
        </InlineNotification>
        <br />
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Issue`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Severity`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Recommended Fix`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Incorrect Parameter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low to High`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If caught early, correct the parameter in the Profile and re-run the deployment. If software was already deployed, you will need to revert the System. This requires creating a new deployment and installing a new AWS Agent.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Server Step Fails`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low to High`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deployment steps can be re-executed if a sub-step fails.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service Stop Step Fails`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Re-run step(s) to stop services, if they fail, stop and disable services manually.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service Account parameter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Make sure to pass domain\\ServiceAccount in this parameter. If using gMSA, no need to pass domain domain.`}</td>
            </tr>
          </tbody>
        </table>
      </AccordionItem>
    </Accordion>
    <h2>{`Execute Actions - Deploy manually`}</h2>
    <Accordion mdxType="Accordion">
      <AccordionItem title="BD-ES-Reports" mdxType="AccordionItem">
        <p>{`OPP must be up and running.`}</p>
        <ol>
          <li parentName="ol">{`Restart SQLServerReportingServices service.`}</li>
          <li parentName="ol">{`Copy command below and populate environment variables with actual information.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`
#Environment variables - Fill out the blank values

$DatabaseServer = ''
$SQLUSER = ''
$SQLPWD = ''
$ServiceUser = ''
$ServicePassword = ''
$EncryptionSecret = ''
$reportUrl = 'http://REPORTSERVER/ReportServer'

[Environment]::SetEnvironmentVariable('DBDeployServer', "$DatabaseServer", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('ReportUrlServer', "$reportUrl", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('AUTHTYPE', 'win', [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLUSER', "$SQLUSER", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLPWD', "$SQLPWD", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('WINUSER', "$ServiceUser", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('WINUPWD', $ServicePassword, [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('InstallDB', 'false', [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SERVICEACCOUNTTYPE', 'custom', [System.EnvironmentVariableTarget]::Process)

choco.exe install ESR -y --force --source=Artifactory

`}</code></pre>
        <ol {...{
          "start": 3
        }}>
          <li parentName="ol">{`Execute the command using PowerShell ISE.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ESR-Clean-DSServerReports" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Copy command below and populate environment variables with actual information.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`#Environment variables - Fill out the blank values

$DatabaseServer = ''
$ReportServer = ''
$SQLUSER = ''
$SQLPWD = ''
$ServiceUser = ''
$ServicePassword = ''

[Environment]::SetEnvironmentVariable('DBDeployServer', "$DatabaseServer", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('AUTHTYPE', 'win', [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLUSER', "$SQLUSER", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLPWD', "$SQLPWD", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('WINUSER', "$ServiceUser", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('WINUPWD', "$ServicePassword", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('ReportUrlServer', "$ReportServer", [System.EnvironmentVariableTarget]::Process)

& "$env:BDAPPLICATIONFILES\\Pyxis MedStation ES DSR\\DatabaseScript\\ESRNewDBScript.ps1"

`}</code></pre>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Execute the command using PowerShell ISE.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-APP-Start-Services" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Copy command below:`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`#Set BD services in Automatic statup type
$cfnServices = Get-Service cfn*;
$bdServices = Get-Service bd*;
$mmsServices = Get-Service mms*;
foreach ($service in $cfnServices) {
      if ($service.Name -ne "CFNExternalMessagingService") {
      Set-Service -Name $service.Name -StartupType Automatic
      }
};
foreach ($service in $bdServices) {
      Set-Service -Name $service.Name -StartupType Automatic
};
foreach ($service in $mmsServices) {
      Set-Service -Name $service.Name -StartupType Automatic
};
Set-Service -Name 'Carefusion.Scheduling.JobScheduler' -StartupType Automatic;
Set-Service -Name 'CareFusion ESDeviceMonitor Service' -StartupType Automatic;
Set-Service -Name 'CentralService' -StartupType Automatic;

#Start BD services
Get-Service -Name "CFN*" | Where-Object {$_.Name -ne "CFNExternalMessagingService"} | Start-Service;
Get-Service -Name "MMS*" | Start-Service;
Get-Service -Name "BD*" | Start-Service;
Start-Service 'CentralService';
Start-Service 'CareFusion ESDeviceMonitor Service';
Start-Service 'Carefusion.Scheduling.JobScheduler';

`}</code></pre>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Execute the command using PowerShell ISE.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-Stop-Services" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Copy command below:`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`
#Stop BD services
Get-Service -Name "CFN*" | Stop-Service;
Get-Service -Name "MMS*" | Stop-Service;
Get-Service -Name "BD*" | Stop-Service;
Stop-Service 'CentralService';
Stop-Service 'CareFusion ESDeviceMonitor Service';
Stop-Service 'Carefusion.Scheduling.JobScheduler';

`}</code></pre>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Execute the command using PowerShell ISE.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-174-Migration-UpdateDataSync" mdxType="AccordionItem">
OPP must be up and running.
1. Copy command below and populate environment variables with actual information.
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`
#Environment variables - Fill out the blank values

$SourceDatabaseServer = ''
$SourceAddressValue = ''
$NewAddressValue = ''
$NewSyncServerAddressName = ''
$SQLUSER = ''
$SQLPWD = ''
$DeploymentDrive = 'C:'

#Install es-database-utilities choco pack

choco.exe upgrade es-database-utilities -y --version=5.4.1.137 --execution-timeout=0 --source=Artifactory

#Update DataSync Values

[Environment]::SetEnvironmentVariable('SQLSERVER', "$SourceDatabaseServer", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLUSER', "$SQLUSER", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLPWD', "$SQLPWD", [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('SQLAUTH', 'win', [System.EnvironmentVariableTarget]::Process)

Import-Module "C:\\BD\\DeploymentScripts\\SqlServer"
Import-Module "C:\\BD\\DeploymentScripts\\DatabaseMigration.psm1"
Update-DataSyncValues -OldSyncServerAddressValue $SourceAddressValue -NewSyncServerAddressValue $NewAddressValue -NewSyncServerAddressName $NewSyncServerAddressName -DeploymentDrive $DeploymentDrive

`}</code></pre>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Execute the command using PowerShell ISE.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-17-QuickStartDatabase" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Download QuickStartDatagenerator-ES-1.7.4_1.7.4.12.zip from EFT.`}</li>
        </ol>
        <ul>
          <li parentName="ul">{`EFT Path: `}
            <pre parentName="li"><code parentName="pre" {...{}}>{`\`\`\`
/GCS-Released (R)/Dispensing/MedStation/Domestic/MedStation ES/Server/1.7.x/Tools/10000415715-00 ES SRV QuickStartDatagenerator-ES-1.7.4_1.7.4.12
\`\`\`
`}</code></pre>
          </li>
        </ul>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Extract the 10000415715-00 QuickStartDatagenerator-ES-1.7.4_1.7.4.12.zip`}</li>
          <li parentName="ol">{`Execute .sql file.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-172-Configure-TDE" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Open PowerShell ISE as Administrator.`}</li>
          <li parentName="ol">{`Copy command below and populate environment variables with actual information.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`
#Environment variables - Fill out the blank values

$DatabaseServer = ''
$SQLUSER = ''
$SQLPWD = ''
$EncryptionSecret = ''
$DatabaseNames = 'DSServerOLTP, DSServerReports, CFUpdateService, CfnSchedulingServices, DSRF, BDMedView, BD_IdentityManagement, BDFHIR'

#Execute the script

C:\\BD\\DeploymentScripts\\Enable-TDE.ps1 -remoteServer $DatabaseServer -auth win -sqlUser $SQLUSER -sqlPassword $SQLPWD -EncryptionSecret $EncryptionSecret -DatabaseNames $DatabaseNames

`}</code></pre>
        <ol {...{
          "start": 3
        }}>
          <li parentName="ol">{`Once you fill out the variable values, execute the script.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-DisableTDE" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Open PowerShell ISE as Administrator.`}</li>
          <li parentName="ol">{`Copy command below and populate environment variables with actual information.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`
#Environment variables - Fill out the blank values

$DatabaseServer = ''
$SQLUSER = ''
$SQLPWD = ''
$EncryptionSecret = ''
$DatabaseNames = 'DSServerOLTP, DSServerReports, CFUpdateService, CfnSchedulingServices, DSRF, BDMedView, BD_IdentityManagement, BDFHIR'

#Execute the script

C:\\BD\\DeploymentScripts\\Disable-TDE.ps1 -remoteServer $DatabaseServer -auth win -sqlUser $SQLUSER -sqlPassword $SQLPWD -EncryptionSecret $EncryptionSecret -DatabaseNames $DatabaseNames

`}</code></pre>
        <ol {...{
          "start": 3
        }}>
          <li parentName="ol">{`Once you fill out the variable values, execute the script.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-173-PICT" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Open PowerShell ISE as Administrator.`}</li>
          <li parentName="ol">{`Run the following command to extract bdhsv-pci choco pack into C:\\BD\\Applications\\PICT folder. If you have different drive letter, update the path.
`}<inlineCode parentName="li">{`nuget install bdhsv-pci -OutputDirectory "C:\\BD\\Applications\\PICT"`}</inlineCode></li>
          <li parentName="ol">{`Execute PICT script. Copy the following command into PowerShell ISE`}</li>
          <li parentName="ol">{`Copy command below and populate environment variables with actual information.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`

$PICTVer = '2.6.3.10';
$pictPath = "$DeploymentDrive\\BD\\Applications\\PICT\\bdhsv-pci.$PICTVer\\tools\\PICT";

#Environment variables - Fill out the blank values

$DeploymentDrive = 'C:'
$DatabaseServer = ''
$ReportServer = ''
$IDMServer = ''
$IDSUrl = 'https://APPFQDN:11998/'
$SQLUSER = ''
$SQLPWD = ''
$ServiceUser = ''
$ServicePassword = ''

Write-Host "PICT Version is: $PICTVer";
Write-Host "PICT Directory is: $pictPath";
[Environment]::SetEnvironmentVariable('idmDbConnectionString', "$DatabaseServer", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('esDbConnectionString', "$DatabaseServer", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('hsvHostAddress', "$ReportServer", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('idmStsURL', "$IDMServer", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('esIdsHostAddress', "$IDSUrl", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('DeploymentDrive', "$DeploymentDrive", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('pictPath', $pictPath, [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('USERID', "$ServiceUser", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('PASSWORD', "$ServicePassword", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('SQLUSER', "$SQLUSER", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('SQLPASSWORD', "$SQLPWD", [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('SQLAUTH', 'win', [System.EnvironmentVariableTarget]::Process);
[Environment]::SetEnvironmentVariable('hsvHostAddress', "$ReportServer", [System.EnvironmentVariableTarget]::Process);


& "$pictPath\\pp-pict.ps1";

`}</code></pre>
        <ol {...{
          "start": 5
        }}>
          <li parentName="ol">{`Once you fill out the variable values, execute the script.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="BD-ES-MAUTool" mdxType="AccordionItem">
OPP must be up and running.
1. Open PowerShell ISE as Administrator.
2. Copy command below and populate environment variables with actual information.
        <pre><code parentName="pre" {...{
            "className": "language-powershell"
          }}>{`
#Environment variables - Fill out the blank values

$SERVICEUSER = ''
$SERVICEPWD = ''


#Execute the script

[Environment]::SetEnvironmentVariable('USER', '$SERVICEUSER', [System.EnvironmentVariableTarget]::Process)
[Environment]::SetEnvironmentVariable('PWD', '$SERVICEPWD', [System.EnvironmentVariableTarget]::Process)

choco.exe feature enable -n useFipsCompliantChecksums
choco.exe install pyxis-mau-tool -y --source=Artifactory

`}</code></pre>
        <ol {...{
          "start": 3
        }}>
          <li parentName="ol">{`Once you fill out the variable values, execute the script.`}</li>
        </ol>
      </AccordionItem>
    </Accordion>
    <h2>{`Post Installation Configuration`}</h2>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Running ETL" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`From the ISS Server, open SQL Server Management Studio (SSMS)`}</li>
          <li parentName="ol">{`Navigate to the SQL Jobs and enable the `}<inlineCode parentName="li">{`ETL – Dispensing System Server Reports Database`}</inlineCode>{` jobs`}</li>
          <li parentName="ol">{`Right-click SQL Server Agent`}</li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Restart`}</inlineCode></li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Yes`}</inlineCode></li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Job Activity Monitor`}</inlineCode></li>
          <li parentName="ol">{`Locate the `}<inlineCode parentName="li">{`ETL – Dispensing System Server Reports Database`}</inlineCode>{` job`}</li>
          <li parentName="ol">{`Confirm Status is not Executing`}</li>
          <li parentName="ol">{`If the job is not currently running, click Start job at step.`}</li>
          <li parentName="ol">{`Verify that the job is completed successfully (This could take a few minutes)`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Verifying Report Schedule Configuration Tool Settings" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Login to the Application server`}</li>
          <li parentName="ol">{`From the desktop, right-click on `}<inlineCode parentName="li">{`Configure Report Composer`}</inlineCode>{` and run as Administrator`}</li>
          <li parentName="ol">{`Navigate to DSR > DSR Configuration`}</li>
          <li parentName="ol">{`Make sure the following parameters are set with the new server values:`}
            <ol parentName="li">
              <li parentName="ol">{`Report Server URL: `}<inlineCode parentName="li">{`http://<ISS FQDN>/ReportServer`}</inlineCode></li>
              <li parentName="ol">{`Report Data Source User Name: `}<inlineCode parentName="li">{`<domain>\\cfnservice`}</inlineCode></li>
              <li parentName="ol">{`Report Data Source Password: `}<inlineCode parentName="li">{`<domain>\\cfnservice`}</inlineCode>{` password`}</li>
              <li parentName="ol">{`Scheduler Server: `}<inlineCode parentName="li">{`<APP Server>`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Make sure the customer provides the information for the following parameters:`}
            <ol parentName="li">
              <li parentName="ol">{`SMTP Server`}</li>
              <li parentName="ol">{`SMTP Server UserName`}</li>
              <li parentName="ol">{`SMTP Server Password`}</li>
              <li parentName="ol">{`SMTP Server Port Number`}</li>
            </ol>
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Configuring the ES system for additional sync servers" mdxType="AccordionItem">
        <p>{`Execute the below steps for a migration only. When migrating from an earlier release of Medstation ES
that includes additional sync servers, the steps below must be followed to properly setup and configure
the ES application server to make it aware of the additional sync servers.`}</p>
        <ol>
          <li parentName="ol">{`On the app server navigate to`}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`<DriveLetter>:\\BD\\Applications\\Pyxis MedStation DataSync Service\\CFNServiceManager
`}</code></pre>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Open `}<inlineCode parentName="li">{`CareFusion.Dispensing.ServiceManagerWindowsService`}</inlineCode>{` using Notepad`}</li>
          <li parentName="ol">{`Update the following value: `}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`<add key="ServiceManagerServiceUrl" value=http://"IP Address of additional Sync Server":10020//>
`}</code></pre>
      </AccordionItem>
      <AccordionItem title="Configuring IdentityServerClientID and IdentityServerClientsecretvalue values for device upgrades" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Open a preferred internet browser`}</li>
          <li parentName="ol">{`In the browser address box, enter `}<inlineCode parentName="li">{`https://localhost`}</inlineCode>{` from the application server`}</li>
          <li parentName="ol">{`Click About `}</li>
          <li parentName="ol">{`Click the black box in the left corner to open the one time password screen
If the page will not display, clear the hostname box in the bindings for the ES https settings.`}</li>
          <li parentName="ol">{`Navigate to `}<inlineCode parentName="li">{`C:\\BD\\Applications\\BD Data`}</inlineCode>{` and open the text file`}</li>
          <li parentName="ol">{`Copy the password from the text file and enter it into the one time password field.`}</li>
          <li parentName="ol">{`Enter an IDS URL in the Identity Server Configuration field.`}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`For example: https://<FQDN of APP Server>:11998
`}</code></pre>
        <ol {...{
          "start": 8
        }}>
          <li parentName="ol">{`Click the Save button that is directly below this box. Do not click the Save box at the bottom of the page as that only controls the Support User Directory selections.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Configuring SQL Server" mdxType="AccordionItem">
        <p>{`Perform the following steps on the Database Server, if it is BD managed, and the ISS Server.`}</p>
        <ol>
          <li parentName="ol">{`Navigate to SQL Server Management Studio`}</li>
          <li parentName="ol">{`Right click on the instance name`}</li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Properties`}</inlineCode></li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Memory`}</inlineCode></li>
          <li parentName="ol">{`Set `}<inlineCode parentName="li">{`Maximum Memory`}</inlineCode>{` according to latest `}<em parentName="li">{`Pyxis ES Deployment Guide: Ch. 1 - Sec. Virtual Machine Specifications`}</em></li>
          <li parentName="ol">{`Select Connections`}</li>
          <li parentName="ol">{`Verify that Allow remote connections is `}<inlineCode parentName="li">{`Enabled`}</inlineCode></li>
          <li parentName="ol">{`Under Database Settings, enable `}<inlineCode parentName="li">{`Compress Backup`}</inlineCode></li>
          <li parentName="ol">{`Close the Server Properties dialog box.`}</li>
          <li parentName="ol">{`Open Command Prompt as the Administrator`}</li>
          <li parentName="ol">{`Execute the following command where `}<inlineCode parentName="li">{`xxxxx-xxxxx-xxxxx-xxxxx-xxxxx`}</inlineCode>{` is replaced with the appropriate license key:`}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`<DriveLetter>:\\BD\\DeploymentScripts\\SQL\\Setup.exe /Q /ACTION=editionupgrade /INSTANCENAME=MSSQLSERVER/IAcceptSQLServerLicenseTerms /Indicateprogress /PID=xxxxx-xxxxx-xxxxx-xxxxx-xxxxx
`}</code></pre>
        <ul>
          <li parentName="ul">{`Customer-provided SQL license: Replace `}<inlineCode parentName="li">{`xxxxx-xxxxx-xxxxx-xxxxx-xxxxx`}</inlineCode>{` with the customer provided key`}</li>
          <li parentName="ul">{`BD-provided SQL license: Replace `}<inlineCode parentName="li">{`xxxxx-xxxxx-xxxxx-xxxxx-xxxxx`}</inlineCode>{` with the key that can be obtained on BD EFT site`}</li>
          <li parentName="ul">{`EFT Path: `}
            <pre parentName="li"><code parentName="pre" {...{}}>{`/GCS-Released (R)/CONTROLLED/SQL Server License
`}</code></pre>
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="Configuring BD Pyxis ES Platform Services" mdxType="AccordionItem">
        <h4>{`Configuring the Redirect URLs for BD Pyxis Link Web`}</h4>
        <p>{`Perform the following procedure to configure the redirect URL settings for BD Pyxis Link Web on the Identity Server (IDS).`}</p>
        <ol>
          <li parentName="ol">{`Open a web browser on the Application or AIO server`}</li>
          <li parentName="ol">{`In the address box, type the following URL: `}<inlineCode parentName="li">{`https://localhost:11998`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Login`}</inlineCode></li>
          <li parentName="ol">{`Enter the BD user credentials`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Support`}</inlineCode></li>
          <li parentName="ol">{`On the client list, click `}<inlineCode parentName="li">{`BD Pyxis Med Link`}</inlineCode></li>
          <li parentName="ol">{`Enter the applicable redirect URLs:`}
            <ol parentName="li">
              <li parentName="ol">{`In the Post Login Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=pyxis_link_web`}</inlineCode></li>
              <li parentName="ol">{`In the Post Logout Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=pyxis_link_web`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Logout`}</inlineCode></li>
          <li parentName="ol">{`Restart IIS`}</li>
          <li parentName="ol">{`Confirm the MedLink URL is accessible (if contracted)`}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`https: //<FQDN_APP_Server>:444/pyxislink/?client_id=pyxis_link_web
`}</code></pre>
        <h4>{`Configuring BD Pyxis Med Link with Epic SSO (Single Sign On)`}</h4>
        <p>{`Perform the following procedure to configure Substitutable Medical Applications, Reusable Technologies (SMART) on Fast Healthcare Interoperability Resource (FHIR) for EpicTM systems.`}</p>
        <ol>
          <li parentName="ol">{`Open a web browser on the Application or AIO server`}</li>
          <li parentName="ol">{`In the address box, type the following URL: `}<inlineCode parentName="li">{`https://localhost:11998`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Login`}</inlineCode></li>
          <li parentName="ol">{`Enter the BD user credentials`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Support`}</inlineCode></li>
          <li parentName="ol">{`On the client list, click `}<inlineCode parentName="li">{`Epic_Hyperspace`}</inlineCode></li>
          <li parentName="ol">{`Enter the applicable redirect URLs:`}
            <ol parentName="li">
              <li parentName="ol">{`In the Post Login Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=epic_hyperspace`}</inlineCode></li>
              <li parentName="ol">{`In the Post Logout Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=epic_hyperspace`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Configuration`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Issuer URI text box, enter the provided issuer information.
NOTE
The presence of the trailing forward slash must match the Epic configuration. Example: `}<inlineCode parentName="li">{`https://epicserveraddress.hospital.org/api/FHIR/DSTU2/`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Metadata endpoint URI text box, enter the provided metadata endpoint. Example: `}<inlineCode parentName="li">{`https://epicserveraddress.hospital.org/api/FHIR/DSTU2/metadata`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Client ID text box, enter the provided Client ID information.`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}
                <ul parentName="li">
                  <li parentName="ul">{`Test: e1ebf823-0c71-4df5-ac56-2b0b224d4d4f `}</li>
                  <li parentName="ul">{`Prod: 72fcc143-9930-4169-b6c8-919868775366`}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <InlineNotification mdxType="InlineNotification">
          <p><strong parentName="p">{`NOTE:`}</strong>{` Epic Client IDs will differ between the test and production environments.
If the Epic Issuer was not entered when previously running the config-ids.bat tool, it needs to be executed again to populate this value. See step 5 in the Configuring IIS bindings and certificate configurations.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 13
        }}>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Submit`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Logout`}</inlineCode></li>
        </ol>
        <h4>{`Configuring IIS`}</h4>
        <ol>
          <li parentName="ol">{`Validate that the customer provided certificates are bound to the PES page on the App Server and default web site on the ISS Server`}</li>
          <li parentName="ol">{`Provision Portal creates self-signed certificate`}</li>
          <li parentName="ol">{`For a three-server deployment, import SSL Certificates from the App Server to the ISS Server and the ISS Server to the App Server.`}</li>
          <li parentName="ol">{`For a three-server deployment, add the FQDN of the App Server and FQDN of the ISS Server into trusted sites for both servers.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Installing and configuring BD HealthSight Viewer" mdxType="AccordionItem">
        <p>{`The BD Pyxis Enterprise Server must be configured with a populated database before you install HSV.
Refer to the Identity Management v4.3 Installation and Support Guide (10000353244-06) to install IDM.
Use the PICT to configure IDM for the necessary settings (PICT is part of Provision Portal deployment steps).`}</p>
        <br />
For installation and configuration instructions, refer to the BD HealthSight Viewer Installation and Support Guide (DIR #10000403807). Proceed to Installing BD Pyxis ES Platform software interfaces.
      </AccordionItem>
      <AccordionItem title="Update BD HealthSight Viewer URL" mdxType="AccordionItem">
        <p>{`Perform the following steps on the AIO/APP Server.`}</p>
        <ol>
          <li parentName="ol">{`Navigate to C:\\BD\\Applications\\Pyxis MedStation ES Server\\Web Application`}</li>
          <li parentName="ol">{`Open Web.config file`}</li>
          <li parentName="ol">{`Navigate to AppSettings`}</li>
          <li parentName="ol">{`Update existing key to reflect the HealthSightViewer Url`}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`<add key="HealthSightViewerUrl" value="https://ISS-SERVER/HsViewer/" />
`}</code></pre>
        <ol {...{
          "start": 5
        }}>
          <li parentName="ol">{`Save and close the config file`}</li>
          <li parentName="ol">{`Restart IIS, start new browser session.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Re-install DataAgent in case of ES Server migration" mdxType="AccordionItem">
        <h4>{`Steps required to be performed on existing ES Server`}</h4>
        <p>{`Perform the following steps to re-install the DataAgent.`}</p>
        <ol>
          <li parentName="ol">{`Go to Data Agent installation location on the current ES Server`}</li>
          <li parentName="ol">{`Double-click the CareFusion.DataAgent.ConfigurationTool.exe file. The Analytics Agent configuration tool will appear on the screen`}</li>
          <li parentName="ol">{`Make a note of installed targets and database credentials for the target that needs to be configured in the new/upgraded ES Server`}</li>
          <li parentName="ol">{`Stop the CareFusion Analytics Agent Service from services.msc and perform a manual transfer of the files to send (Refer DA ISG to run the command for manual transfer)`}</li>
          <li parentName="ol">{`Move the contents of the installation directory of Data Agent to a backup directory`}</li>
          <li parentName="ol">{`Uninstall the Analytics Agent using the Windows Add or Remove programs feature`}</li>
          <li parentName="ol">{`Delete remaining files from the installation directory`}</li>
        </ol>
        <h4>{`Steps required to be performed on new/upgraded ES Server`}</h4>
        <p>{`Perform the following steps on new/upgraded servers.`}</p>
        <ol>
          <li parentName="ol">{`Install the new version of Analytics Agent (Refer compatibility matrix to find the correct DA version as per the ESR version)`}</li>
          <li parentName="ol">{`Run CareFusion.DataAgent.ConfigurationTool.exe file and configure the targets for the new installation, like the one created in the previous ES Server so that the DA file will contain the same PyxisSiteKey and ConsoleName in the file name. (As noted in step2)`}</li>
          <li parentName="ol">{`Click on Test Connection to make sure the Connection to DB and DA web service is successful.`}</li>
          <li parentName="ol">{`Copy the Output folder `}<inlineCode parentName="li">{`AnalyticsAgentInstallDirectory/Analytics Agent\\targets\\ConsoleName`}</inlineCode>{` and its contents from the backup folder and paste it in the new installation target folder. (As noted in step3)`}</li>
          <li parentName="ol">{`Perform manual extraction and transfer for the target configured on new ES Server`}</li>
          <li parentName="ol">{`Start the CareFusion Analytics Agent Service from services.msc`}</li>
          <li parentName="ol">{`Observe that data file gets extracted and transferred under the Sent Directory`}</li>
          <li parentName="ol">{`To update to the latest extraction version please follow the knowledge article shared at the bottom of this section`}</li>
        </ol>
        <InlineNotification mdxType="InlineNotification">
          <p><strong parentName="p">{`NOTE:`}</strong>{` Refer the below ISG document for DataAgent workflow understanding (ISG DIR # 10000216694).`}</p>
          <br />
Refer the below Compatibility Matrix for knowing the latest DataAgent/Extraction version (DIR # 10000224530).
        </InlineNotification>
        <br />
        <h4>{`KA for manually upgrading to the latest extraction version`}</h4>
        <ol>
          <li parentName="ol">{`Login to the device machine`}</li>
          <li parentName="ol">{`Install latest Data Agent version 2.13`}</li>
          <li parentName="ol">{`Copy the latest Data Agent config release version 1.8`}</li>
          <li parentName="ol">{`Go to installer folder and run Installer `}<inlineCode parentName="li">{`Setup_Analytics Agent - Configuration Updates-1.8.0.2.exe`}</inlineCode></li>
          <li parentName="ol">{`Click on Next to continue`}</li>
          <li parentName="ol">{`Specify a local folder where you want to extract the config files and click on Install`}</li>
          <li parentName="ol">{`Click on Install, Next and Finish to complete the Installer process.`}</li>
          <li parentName="ol">{`Go to the local folder where all config files got extracted and navigate to this folder to get the latest files `}<inlineCode parentName="li">{`AgentDownloads\\ExtractionVersions\\Product_23\\Extraction_109`}</inlineCode></li>
          <li parentName="ol">{`Go to the device machine and navigate to the below mentioned folder location `}<inlineCode parentName="li">{`DA INSTALL DIRECTORY/targets/TARGET NAME/config`}</inlineCode></li>
          <li parentName="ol">{`Replace existing files `}<inlineCode parentName="li">{`SpdAgentExtraction.23.xml`}</inlineCode>{` and `}<inlineCode parentName="li">{`SpdTableExtraction.23.xml`}</inlineCode>{` with new one`}</li>
          <li parentName="ol">{`Open `}<inlineCode parentName="li">{`SpdTargetConfiguration.xml`}</inlineCode>{` and update value from 108 to 109 for key ExtractionVersion in that file`}</li>
          <li parentName="ol">{`Save and close the file`}</li>
          <li parentName="ol">{`Run a manual extraction and verify the new file got generated with 109 extraction version for PID 23`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Configuring Sync 2.0 using the server UI" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Log on with BD support credentials`}</li>
          <li parentName="ol">{`Navigate to Settings > Sync Servers > Configure 2.0`}</li>
          <li parentName="ol">{`Set the Client Sync Server URL and Port Number field to the fully qualified name of the app server followed by a colon and port number 50051. Even though the UI indicates it is obsolete, it is required for devices on ES 1.7.0 through ES 1.7.2 via a non secure port of 50051`}</li>
          <li parentName="ol">{`Set the Client Secure Sync Server URL and Port Number field to the fully qualified name of the app server followed by a colon and port number 50052. This is the port the devices will be syncing to once they are upgraded to ES 1.7.4 and higher`}</li>
          <li parentName="ol">{`The rest of the Sync 2.0 settings should remain at their default values and should only be changed if necessary`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Adding a core synchronized server" mdxType="AccordionItem">
        <InlineNotification mdxType="InlineNotification">
          <p><strong parentName="p">{`NOTE:`}</strong>{` At least one server must be installed and then configured. For more installation information about installing a separate Sync VM, see Installing the server software using the PCI.`}</p>
        </InlineNotification>
        <br />
        <ol>
          <li parentName="ol">{`Log on to the Pyxis Enterprise Server web application using the support user credentials`}</li>
          <li parentName="ol">{`From the menu bar, select Settings > Sync Servers > Configure`}</li>
          <li parentName="ol">{`Click Add Sync Server`}</li>
          <li parentName="ol">{`On the Add a Sync Server page, enter the following:`}
            <ol parentName="li">
              <li parentName="ol">{`Name `}<inlineCode parentName="li">{`Sync server name`}</inlineCode></li>
              <li parentName="ol">{`IP / Fully Qualified Domain Name `}<inlineCode parentName="li">{`Sync server FQDN`}</inlineCode></li>
              <li parentName="ol">{`If this is a primary server, select the Primary Server check box.`}</li>
              <li parentName="ol">{`Click Add.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Repeat steps 1–4 for each additional sync server.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Verifying BD Services are enabled" mdxType="AccordionItem">
        <p>{`Verify the following services are enabled`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`BD Active Directory Synchronization Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Archive Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Archive Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Bulletin Print Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Central Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Data Manager Integration Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Data Synchronization Server Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Dispensing Device Services`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD ESDeviceMonitor Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD External Messaging Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Job Scheduler Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Maintenance Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Med Inventory Optimization Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS API`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS Message Hub Cluster Seed`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS Message Hub Configuration Application`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS Message Hub Configuration UI`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS Message Hub HSDM Downstream Module`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS Message Hub Message Queue`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD MMS Message Hub Upstream Proxy`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Mobile Dock Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Notification Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Package Publishing Service`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD Pyxis External Inbound Processors Service`}</em></li>
        </ul>
      </AccordionItem>
      <AccordionItem title="Verifying SQL Jobs are enabled" mdxType="AccordionItem">
        <p>{`Verify the following SQL Jobs are enabled`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Backup Database - DIFF`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Backup Database - FULL`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Backup Database - LOG`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Check Database Integrity`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Optimize Index`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Purge rnt_AttentionNoticeStatuses`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Purge rnt_UserActionTracking`}</em></li>
          <li parentName="ul"><em parentName="li">{`[BDMedView]`}{` Update Statistics`}</em></li>
          <li parentName="ul"><em parentName="li">{`BD_IndentityManagmentSheduledBackup`}</em></li>
          <li parentName="ul"><em parentName="li">{`CfnSchedulingServices-ArchiveJobs`}</em></li>
          <li parentName="ul"><em parentName="li">{`CfnSchedulingServices-JobsPurge`}</em></li>
          <li parentName="ul"><em parentName="li">{`CfnSchedulingServices-Log4NetPurge`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server DSRF Database - FULL`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server Reports Database - DIFF`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server Reports Database - FULL`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server Reports Database - LOG`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server Scheduling Services Database - FULL`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server User Database - DIFF`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server User Database - FULL`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - Dispensing System Server User Database - LOG`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseBackup - System Databases - FULL`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseIntegrityCheck - Dispensing System Server DSRF Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseIntegrityCheck - Dispensing System Server Reports Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseIntegrityCheck - Dispensing System Server Scheduling Services Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`DatabaseIntegrityCheck - System Databases`}</em></li>
          <li parentName="ul"><em parentName="li">{`DB_Maintenance - Clear sysssislog From DSServerReports`}</em></li>
          <li parentName="ul"><em parentName="li">{`DB_Maintenance - Sync Session Time Out of RC With ES`}</em></li>
          <li parentName="ul"><em parentName="li">{`Delete OutboundMessage Missing TX`}</em></li>
          <li parentName="ul"><em parentName="li">{`ETL - Dispensing System Server Reports Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`ETL - Dispensing System Server Reports Database - CIISafeData Retention`}</em></li>
          <li parentName="ul"><em parentName="li">{`ETL - Dispensing System Server Reports Database - Purge Log`}</em></li>
          <li parentName="ul"><em parentName="li">{`IndexOptimize - Dispensing System Server DSRF Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`IndexOptimize - Dispensing System Server Reports Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`IndexOptimize - Dispensing System Server Scheduling Services Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`PLX IndexMaintenance`}</em></li>
          <li parentName="ul"><em parentName="li">{`PurgeAudit - Purge audit logs prior 365 days - DSRF`}</em></li>
          <li parentName="ul"><em parentName="li">{`ServerMaint - Check Recent Backups`}</em></li>
          <li parentName="ul"><em parentName="li">{`ServerMaint - Check Recent Backups - DSRF`}</em></li>
          <li parentName="ul"><em parentName="li">{`ServerMaint - Check Recent Backups - Scheduling Services`}</em></li>
          <li parentName="ul"><em parentName="li">{`ServerMaint - Purge Agent History`}</em></li>
          <li parentName="ul"><em parentName="li">{`syspolicy_purge_history`}</em></li>
          <li parentName="ul"><em parentName="li">{`UpdateStatistics - Dispensing System Server DSRF Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`UpdateStatistics - Dispensing System Server Reports Database`}</em></li>
          <li parentName="ul"><em parentName="li">{`UpdateStatistics - Dispensing System Server Scheduling Services Database`}</em></li>
        </ul>
      </AccordionItem>
      <AccordionItem title="BD Databases" mdxType="AccordionItem">
        <p>{`List of ES System release databases`}</p>
        <ul>
          <li parentName="ul"><em parentName="li">{`BD_IdentityManagement`}</em></li>
          <li parentName="ul"><em parentName="li">{`BDMedView`}</em></li>
          <li parentName="ul"><em parentName="li">{`CfnSchedulingServices`}</em></li>
          <li parentName="ul"><em parentName="li">{`CFUpdateService`}</em></li>
          <li parentName="ul"><em parentName="li">{`DSRF`}</em></li>
          <li parentName="ul"><em parentName="li">{`DSServerOLTP`}</em></li>
          <li parentName="ul"><em parentName="li">{`DSServerReports`}</em>
            <br />
Decrypting the database(s) on a ES 1.6.1 system or earlier
          </li>
        </ul>
        <ol>
          <li parentName="ol">{`Determine if the ES database(s) are encrypted:`}
            <ol parentName="li">
              <li parentName="ol">{`Run the query use master exec dbd_listdb to determine if ES databases are encrypted before preparing for migration.`}</li>
              <li parentName="ol">{`This query result lists all the database instances with encryption.`}</li>
              <li parentName="ol">{`The database name will be listed under the Name column. If no entries or errors are returned, then the database is unencrypted.`}</li>
              <li parentName="ol">{`If there are encrypted databases, make note in order to prepare for migration.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`To decrypt the database do the following:`}
            <ol parentName="li">
              <li parentName="ol">{`On the database server, navigate to C:\\BD Tools\\DB Encryption.`}</li>
              <li parentName="ol">{`Run DB Decryption PCI.exe.`}</li>
              <li parentName="ol">{`Click Install.`}</li>
              <li parentName="ol">{`Enter the decryption tool password. For assistance with the decryption tool password refer to the server checklist.`}
                <br />
Decrypting the database(s) on a ES 1.7.2 system and higher
In ES 1.7.2 BD introduced Microsoft SQL TDE (transparent data encryption) to encrypt the database instead of DBDefence.
              </li>
            </ol>
          </li>
          <li parentName="ol">{`Navigate to Provision Portal`}</li>
          <li parentName="ol">{`Login and navigate to the deployment.`}</li>
          <li parentName="ol">{`On the Deployment page, select More Actions.`}</li>
          <li parentName="ol">{`Select Execute Action.`}</li>
          <li parentName="ol">{`Select BD-ES-DisableTDE.`}</li>
          <li parentName="ol">{`Fill out the necessary parameters and click Execute.`}</li>
        </ol>
      </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      